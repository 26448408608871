<template>
  <div class="page">
    <el-form inline>
      <el-form-item label="销货单位：">
        <el-input />
      </el-form-item>
      <el-form-item label="单据日期：">
        <el-date-picker />
      </el-form-item>
      <el-form-item label="单据编号：">
        <el-input readonly />
      </el-form-item>
    </el-form>
    <el-table border>
      <el-table-column
        header-align="center"
        align="center"
        type="index"
        width="50"
      ></el-table-column>
      <el-table-column header-align="center" align="center" width="80">
        <template slot-scope="scope">
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-around;
            "
          >
            <i class="el-icon-plus" style="cursor: pointer"></i>
            <i class="el-icon-delete" style="cursor: pointer"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="支出类别">
        <template slot-scope="scope">
          <el-select></el-select>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="金额">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
      <el-table-column header-align="center" align="center" label="备注">
        <template slot-scope="scope">
          <el-input></el-input>
        </template>
      </el-table-column>
    </el-table>
    <el-form style="margin-top: 25px" label-position="left" label-width="100px">
      <el-row :gutter="10">
        <el-col :span="4">
          <el-form-item label="结算账户:">
            <el-select></el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item label="付款金额:">
            <el-input></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col>
          <el-form-item label="制单人:">
            <span>name</span>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "otherExpense",
};
</script>

<style scoped>
.page {
  background-color: #fff;
  padding: 20px;
}
</style>
